<template>
  <div class="m-lg-1 ml-2">
    <router-link v-if="to" :to="to" class="color-primary font-weight-500">
      <div class="comment_bubble">{{ $t("button.back") }}</div>
      <div class="triangle_left"></div>  
    </router-link>
    <a v-else href="javascript:;" class="color-primary font-weight-500" @click="onBackClick">
      <div class="comment-bubble"><span class="line-height">{{ $t("button.back") }}</span></div>
    </a>
  </div>
</template>
<script>
export default {
  name: 'Back',
  props: {
    to: {
      required: false,
      type: String,
      default: () => ''
    }
  },
  methods: {
    onBackClick () {
      return this.$router.go(-1)
    }
  }
}
</script>
<style scoped>
a:hover{
  text-decoration: none;
}
.comment-bubble {
    width: 68px;
    height: 36px;
    background:rgba(115, 51, 174, 0.5);
    position: relative;
    color:white;
    padding-left: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.comment-bubble:before {
    content: "";
    width: 0;
    height: 0;
    top:0px;
    right:100%;
    position: absolute;
    border-top: 18px solid transparent;
    border-right: 10px solid rgba(115, 51, 174, 0.5);;
    border-bottom: 18px solid transparent;
}
.line-height {
  line-height: 36px;
}
</style>
