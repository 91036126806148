<template>
  <div class="bg-white h-full">
    <div class="container mx-auto px-3">
      <div class="py-4">
        <Header class="mb-3" />
        <div>
          <Filters
            class="border-b border-t-0 border-r-0 border-l-0 border-solid border-gray-200 pb-3 mb-3"
          />

          <div class="row">
            <div class="col-lg-8">
              <h3>{{ `${$t('Tin nổi bật')}` }}</h3>
              <div v-for="item in listTopic" :key="item.id" v-loading="isLoading" class="mb-3">
                <TopicItem
                  :topicItem="item"
                  @bookmark="changeBookmark"
                  @vote="changeVote"
                  @delete="deleteTopic"
                />
              </div>
            </div>
            <div class="col-lg-4" v-if="listBookmark.length">
              <h3>{{ `${$t('Tin đã lưu')} (${listBookmark.length})` }}</h3>
              <div v-for="item in listBookmark" class="mb-3" :key="item.id" v-loading="isLoading">
                <TopicItem :topicItem="item" type="bookmark" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/Back.vue'
import Header from '@/pages/CircleV2/CircleQAndA/Header.vue'
import Filters from '@/pages/CircleV2/CircleQAndA/Filters.vue'
import { TopicItem } from '@/pages/CircleV2/CircleQAndA/TopicItem'
import Vue from 'vue'

export default {
  name: 'CircleQAndA',
  components: { Back, Header, Filters, TopicItem },
  mounted () {
    this.fetchListTopic()
  },
  data () {
    return {
      listTopic: [],
      listBookmark: [],
      params: {
        tag_id: '',
        keyword: '',
        created_by: '',
        sort_by: 'created_at',
        order: 'desc',
        page_size: 10,
        page_num: 1,
        circle_id: this.$route.params?.id
      },
      isLoading: false
    }
  },
  methods: {
    async fetchListTopic () {
      try {
        this.isLoading = true
        const param = {
          ...this.params,
          sort_by: ''
        }
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .fetchListTopic(param)
        if (response.status === 200) {
          this.isLoading = false
          this.listTopic = response.data.data
          this.listBookmark =
            this.listTopic?.filter(elm => elm.is_bookmarked) || []
        }
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    changeBookmark (value) {
      try {
        if (value.is_bookmarked === true) this.addBookmark(value.id)
        else this.removeBookmark(value.id)
      } catch (error) {
        console.log(error)
      }
    },
    changeVote (value) {
      try {
        if (value.is_voted === true) this.addVote(value.id)
        else this.removeVote(value.id)
      } catch (error) {
        console.log(error)
      }
    },
    async addBookmark (id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .addBookmark({ circle_topic_id: id })
        if (response.status === 200) {
          this.$message.success('Lưu tin thành công!')
          this.fetchListTopic()
        } else {
          //
        }
      } catch (error) {
        this.fetchListTopic()
        console.log(error)
      }
    },
    async removeBookmark (id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .removeBookmark(id)
        if (response.status === 200) {
          this.$message.success('Bỏ lưu tin thành công!')
          this.fetchListTopic()
        } else {
        }
      } catch (error) {
        this.fetchListTopic()
        console.log(error)
      }
    },
    async addVote (id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .addVote({ type_able_id: id })
        if (response.status === 200) {
          this.$message.success('Vote thành công!')
          this.fetchListTopic()
        } else {
          //
        }
      } catch (error) {
        this.fetchListTopic()
        console.log(error)
      }
    },
    async removeVote (id) {
      try {
        const response = await Vue.prototype.$rf
          .getRequest('QAndARequest')
          .removeVote(id)
        if (response.status === 200) {
          this.$message.success('Bỏ vote thành công!')
          this.fetchListTopic()
        } else {
          //
        }
      } catch (error) {
        this.fetchListTopic()
        console.log(error)
      }
    },
    deleteTopic (id) {
      this.$confirm(
        this.$t(`Bạn chắc chắn muốn xóa Topic này?`),
        this.$t('Cảnh báo')
      )
        .then(async _ => {
          const response = await Vue.prototype.$rf
            .getRequest('QAndARequest')
            .deleteTopic(id)
          if (response.status === 200) {
            this.$message.success('Xoá topic thành công!')
            this.fetchListTopic()
          } else {
            //
          }
        })
        .catch(_ => {})
    }
  }
}
</script>
