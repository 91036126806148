<template>
  <div>
    <div class="flex mb-6">
      <ArrowLeft class="mr-3 w-8 h-8 cursor-pointer" @click.native="onGoBack" />
      <h1 class="text-black text-2xl font-bold text-center md:text-left mb-0">{{ $t('Q&A')}}</h1>
    </div>
    <div>
      <el-alert type="info" show-icon class="bg-blue-50 txt-pri" :closable="false">
        <!-- <template slot="title">
          <div class="text-base txt-pri">{{ $t('Q&A') }}</div>
        </template>-->
        <div
          class="text-base txt-pri"
        >{{ $t('Q&A là nơi Bệnh nhân tìm được sự hỗ trợ từ cộng đồng, trao đổi giữa Bệnh nhân và Bác sĩ, NVYT về các chủ đề có ý nghĩa cho hành trình chăm sóc bảo vệ sức khoẻ. Mọi thông tin được bảo mật. Mọi cá nhân được bảo vệ.') }}</div>
      </el-alert>
    </div>
  </div>
</template>

<script>
import { ArrowLeft } from '@/components/Common/Icons'

export default {
  name: 'CircleQAndAHeader',
  components: { ArrowLeft },
  data () {
    return {}
  },
  methods: {
    onGoBack () {
      return this.$router.go(-1)
    }
  }
}
</script>
