<template>
  <TopicItemSimple v-if="layout === 'simple'" />

  <div v-else>
    <el-card shadow="hover" body-style="padding: 12px">
      <div class="flex justify-between gap-2 cursor-pointer">
        <div
          v-if="type !== 'bookmark'"
          class="flex items-center flex-col cursor-pointer"
          style="flex: 1; padding: 4px; height: fit-content"
          @click="handleUpvote"
        >
          <img
            v-if="isVoted"
            src="/images/icon-upvote-active.svg"
            alt
            style="width: 24px; height: 24px"
          />
          <img v-else src="/images/icon-upvote.svg" alt style="width: 24px; height: 24px" />
          <span class="font-semibold">{{topicItem.vote_count}}</span>
        </div>
        <div class="topic-content" style="flex: 12">
          <div class="flex items-center gap-2">
            <img src="/images/avatar-default.svg" alt class="w-4 h-4" />
            <span
              class="font-medium name"
            >{{topicItem.is_incognito === 2 ? 'Ẩn danh' : topicItem.user && topicItem.user.name}}</span>
            <div v-if="type !== 'bookmark'" class="dot"></div>
            <span v-if="type !== 'bookmark'" style="color: #19B3A3">Chuyên gia</span>
            <span>{{appUtils.formatDateTime(topicItem.created_at)}}</span>
          </div>
          <div class="mt-3" @click="handleOpenTopicDetail">
            <p class="title">{{topicItem.title}}</p>
            <span class="description" v-if="type !== 'bookmark'" v-html="topicItem.description"></span>
            <div v-if="type !== 'bookmark'" class="mt-2">
              <el-tag
                v-for="tag in topicItem.circle_topic_tags.slice(0,5)"
                class="flex items-center mr-2 mb-2"
                type="info"
                :key="tag.id"
              >{{ tag.circle_tag && tag.circle_tag.title }}</el-tag>

              <el-tooltip placement="top">
                <div slot="content">{{listTopicTooltip}}</div>
                <el-tag
                  class="flex items-center mr-2 mb-2"
                  type="info"
                  v-if="topicItem.circle_topic_tags.length > 4"
                >+{{topicItem.circle_topic_tags.slice(5).length}}</el-tag>
              </el-tooltip>
            </div>
          </div>
          <div class="flex items-center mt-2 justify-between" v-if="type !== 'bookmark'">
            <div class="flex items-center gap-3">
              <div class="flex items-center gap-1">
                <img src="/images/icon-comment.svg" alt style="width: 20px; height: 20px" />
                <span>{{topicItem.comment_count}} bình luận</span>
              </div>
              <div class="flex items-center gap-1">
                <img src="/images/icon-notification.svg" alt style="width: 20px; height: 20px" />
                <span>Theo dõi</span>
              </div>
            </div>
            <div class="flex items-center gap-3">
              <div class="flex items-center gap-1 cursor-pointer" @click="handleDeleteTopic">
                <img src="/images/icon-delete.svg" alt style="width: 20px; height: 20px" />
                <span class="delete">Xoá</span>
              </div>
              <div
                class="flex items-center gap-1 cursor-pointer"
                @click="$router.push({name: 'AddTopic', params: {type: 'edit', topic_id: topicItem.id}})"
              >
                <img src="/images/icon-edit.svg" alt style="width: 20px; height: 20px" />
                <span class="edit">Chỉnh sửa</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="type !== 'bookmark'"
          class="flex items-center flex-col bookmark cursor-pointer"
          style="flex: 1; padding: 4px; height: fit-content"
          @click="handleAddBookmark"
        >
          <img
            v-if="!isBookmarked"
            src="/images/icon-bookmark.svg"
            alt
            style="width: 24px; height: 24px"
          />
          <img v-else src="/images/icon-bookmark-active.svg" alt style="width: 24px; height: 24px" />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import TopicItemSimple from './TopicItemSimple.vue'
import appUtils from '@/utils/appUtils'

export default {
  name: 'CircleQAndATopicItem',
  components: { TopicItemSimple },
  props: {
    layout: {
      type: String // default, simple
    },
    topicItem: {},
    type: ''
  },
  mounted () {
    this.isBookmarked = this.topicItem?.is_bookmarked
    this.isVoted = this.topicItem?.is_voted
  },
  data () {
    return {
      appUtils,
      isBookmarked: false,
      isVoted: false
    }
  },
  computed: {
    listTopicTooltip () {
      return this.topicItem?.circle_topic_tags
        .slice(5)
        ?.map(elm => ` ${elm.circle_tag.title}`)
        .toString()
    }
  },
  methods: {
    handleAddBookmark () {
      this.isBookmarked = !this.isBookmarked
      this.$emit('bookmark', {
        is_bookmarked: this.isBookmarked,
        id: this.topicItem.id
      })
    },
    handleUpvote () {
      this.isVoted = !this.isVoted
      this.$emit('vote', { is_voted: this.isVoted, id: this.topicItem.id })
    },
    handleDeleteTopic () {
      this.$emit('delete', this.topicItem.id)
    },
    handleOpenTopicDetail () {
      this.$router.push({
        path: `/circle/${this.$route.params.id}/topics/${this.topicItem.id}`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.topic-content {
  .name {
    color: #20409b;
  }
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #20409b;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  .description {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .delete {
    &:hover {
      color: #ea6a6a;
      text-decoration: underline;
    }
  }
  .edit {
    &:hover {
      text-decoration: underline;
      color: #20409b;
    }
  }
}
</style>
