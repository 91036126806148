<template>
  <div class="border p-2">
    <div class="flex justify-between">
      <div class="flex-1">
        <div>
          <ImageBox
            v-if="member && member.avatar"
            class="avatar avatar-sm avatar-rounded object-cover object-top border"
            avatar
            :source="getImageURL(member.avatar)"
            :alt="member.name || member.email"
          />
          <span
            v-if="!member || !member.avatar"
            class="avatar avatar-sm avatar-rounded bg-pri text-white"
          >
            {{
            member.name && member.name.charAt(0) ||
            member.email && member.email.charAt(0)
            }}
          </span>
        </div>
      </div>
      <div class="text-xs">{{ $t('đăng 18 giờ trước') }}</div>
    </div>
  </div>
</template>

<script>
import ImageBox from '@/components/Common/Image.vue'

export default {
  name: 'CircleQAndATopicItemSimple',
  components: { ImageBox },
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {}
  },
  computed: {
    member () {
      return this.data?.member || {}
    }
  }
}
</script>
